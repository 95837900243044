import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
              <Text variant='p'>
              Erick Gomez attended the University of La Salle, where he received his Bachelors in Cybernetics and Computer Science in 2001 and his Masters of Science in Economy & Finances.

                Mr. Gomez has led teams of engeeners at Amazon and previously he was a Vice President of JP Morgan & Co. He has Co-founded 2 startups. And, he has led teams of over +100 engineers. 
                </Text>
                <Text variant='p'>
                His previous responsibility directly affected the revenue of over $12 Billion of dollars by leading Advertising Marketplace Ad Media Reporting & Analytics @ Amazon.
                </Text>
                <Text variant='p'>
                Mr. Gomez is a quant aficionado that trades stocks, futures and options with his own capital. Lately Mr. Gomez has been diversifying his portfolio in Real Estate and creating syndicates for bigger deals. 
                </Text>

              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Real Estate'>
                <Card variant='paper'>
                👉 I enjoy looking for different deals and opportunities. If you have any questions do not hesitate reaching out about a deal. I can take a look and guide you and very quickly I can tell you if is a Hero or a Zero! 💵🏘
                </Card>
              </Section>
              <Divider />
              <Section title='Trading'>
                <Card variant='paper'>
                 I'm a quant afficionado and I'm always looking for stratetegies for trading options, futures and ETFs. Signup to my newsletter. I write often about different strategies. 📈💹💰🤑
                </Card>
              </Section>
              <Divider />
              <Section title='Crypto'>
                <Card variant='paper'>
                 Now days seems like people are experts in Crypto. Me and my buddies, we've started using crypto back in 2014 and yes some of our coins were $150. Nobody would though that it would reach theses prices. But who is complaining 😂
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
